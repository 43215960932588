<template>
    <div class="logic-view">
        <TeleportComponent to="#navigation">Logic</TeleportComponent>
    </div>
</template>

<script>
export default {
    name: 'Logic'
}
</script>

<style scope lang="scss">
.logic-view {
    height: auto;

    min-height: 100vh;
    max-height: 100vh;

    overflow-y: auto;
}
</style>
